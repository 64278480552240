.App {
  padding: 20px;
  background-color: rgb(32, 31, 30);
  min-height: 100vh;
  height: 100%;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

h1 {
  color: white;
}

.iteration-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.iteration-card {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 5px;
  background-color: rgb(50, 49, 48);
  color: white;
}

.iteration-card:hover {
  cursor: pointer;
  /* background-color: rgba(255, 255, 255, 0.2); */
  text-decoration: none;
}

.task-id {
  font-weight: bold;
}

.iteration-header {
  display: flex;
  align-items: center;
}

.iteration-header > p {
  margin: 0;
  margin-left: 20px;
}

/* ul {
  list-style-type: none;
} */

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iteration-profile {
  width: 48px;
  height: 48px;
}

img {
  object-fit: cover;
  height: 100%;
  border-radius: 4px;
}

h2 {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.info {
  margin-left: 16px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
